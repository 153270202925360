module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Magnus UI","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"static/images/favicon/favicon-32x32.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-code-buttons/gatsby-browser.js'),
      options: {"plugins":[],"buttonContainerClass":"copy-snippet-button","buttonClass":"group relative","buttonText":"<div class=\"group-hover:block\">Copy</div>","svgIconClass":"copy-snippet-svg","tooltipText":"","toasterClass":"copy-snippet-toaster","toasterTextClass":"copy-snippet-toaster-text","toasterText":"Copied to clipboard!","toasterDuration":100000},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-149664330-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
